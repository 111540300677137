<template>
  <DefaultTemplate
    id="criteria-credit"
    v-loading.lock="loadingData"
    element-loading-text="กำลังโหลดข้อมูล..."
    element-loading-spinner="el-icon-loading"
  >
    <div class="box-card">
      <div class="box-name-page mg-b-5">
        <p class="title">เกณฑ์พิจารณาจัดเกรดสินเชื่อ</p>
        <div class="is-flex ai-center">
          <el-switch
            v-if="checkKeepByRole()"
            v-model="switchChangeType"
            :active-text="
              switchChangeType == true
                ? 'แสดงเกณฑ์ที่ถูกจัดเก็บ'
                : 'ซ่อนเกณฑ์ที่ถูกจัดเก็บ'
            "
            @change="changeType"
          >
          </el-switch>
          <el-button
            id="btnModalAdd"
            type="primary"
            class="mg-r-5"
            @click="showModalAdd"
            v-if="checkPermissionMore()"
          >
            <i class="fas fa-plus"></i> เพิ่มเกณฑ์พิจารณา
          </el-button>
          <el-input
            id="searchCriteria"
            placeholder="ค้นหาชื่อเกณฑ์/ค้นหาปี"
            suffix-icon="el-icon-search"
            v-model="dataSearch"
            class="search"
            @input="searchCriteria"
            clearable
          >
          </el-input>
          <el-button
            class="mg-r-5 btn-round color-c4"
            v-if="checkPermissionMore()"
            @click="getHistoryLog"
            id="openTransectionLog"
          >
            <i class="fas fa-history"></i>
          </el-button>
        </div>
      </div>
      <div v-loading.lock="loading" element-loading-text="กำลังโหลดข้อมูล...">
        <div
          v-loading.lock="loadingNormal"
          element-loading-text="กำลังโหลดข้อมูล..."
        >
          <div
            v-if="!loading && !loadingData"
            class="animate__animated animate__fadeIn animate__slow"
          >
            <el-row :gutter="20" v-if="dataRes.data.length > 0">
              <el-form label-position="top" class="set-form">
                <el-col
                  :span="24"
                  :md="12"
                  class="mg-t-5"
                  v-for="(data, index) in dataRes.data"
                  :key="index"
                >
                  <div
                    class="box-criteria"
                    :class="data.active ? 'active' : ''"
                  >
                    <div class="criteria-left">
                      <div
                        class="box"
                        @click="openModalActive(data._id)"
                        :class="
                          data.active || checkPermissionMore() == false
                            ? 'cursor-none'
                            : ''
                        "
                      >
                        <div style="min-height: 18px" v-if="data.active">
                          <i class="fas fa-check-circle"></i>
                        </div>
                        <span v-if="data.active">Active</span>
                        <span v-else>Deactivate</span>
                      </div>
                      <div>
                        <p class="color-35 font-20 mg-t-0 name-criteria">
                          {{ data.criterionName }}
                        </p>
                        <p class="color-75 font-18 mg-b-0">
                          <!-- ปี {{ data.criterionYear }} -->
                          วันที่สร้างเกณฑ์ {{ dateThai(data.createdAt) }}
                        </p>
                      </div>
                    </div>
                    <div class="criteria-right">
                      <router-link
                        :to="`/criteria-credit/detail/${data._id}`"
                        class="color-main"
                      >
                        <el-button class="mg-r-6 more-criteria color-c4">
                          ดูรายละเอียดเกณฑ์
                        </el-button>
                      </router-link>
                      <el-dropdown trigger="click" v-if="checkPermissionMore()">
                        <span class="el-dropdown-link">
                          <el-button class="btn-round criteria color-c4 pd-x-5"
                            ><i class="fas fa-ellipsis-v"></i>
                          </el-button>
                        </span>
                        <el-dropdown-menu
                          slot="dropdown"
                          class="dropdown-criteria"
                        >
                          <el-dropdown-item>
                            <div @click="copyCriterion(data._id)">
                              <i class="fas fa-copy"></i> คัดลอกเกณฑ์
                            </div>
                          </el-dropdown-item>
                          <el-dropdown-item
                            :disabled="data.active == true ? true : false"
                          >
                            <div @click="modalCriterion(data._id, 'edit')">
                              <i class="fas fa-edit"></i>แก้ไขชื่อเกณฑ์
                            </div>
                          </el-dropdown-item>
                          <!-- <el-dropdown-item
                      ><i class="fas fa-cog"></i
                      >ตั้งค่าคุณสมบัติเกณฑ์</el-dropdown-item
                    > -->
                          <el-dropdown-item
                            :disabled="data.active == true ? true : false"
                          >
                            <div @click="modalCriterion(data._id, 'manage')">
                              <i class="fas fa-list-ul"></i>จัดการข้อมูลเกรด
                            </div></el-dropdown-item
                          >
                          <el-dropdown-item
                            v-if="checkKeepByRole()"
                            :disabled="data.active == true ? true : false"
                          >
                            <div @click="modalCriterion(data._id, 'keep')">
                              <i class="fas fa-archive"></i>จัดเก็บเกณฑ์
                            </div></el-dropdown-item
                          >
                        </el-dropdown-menu>
                      </el-dropdown>
                    </div>
                  </div>
                </el-col>
              </el-form>
              <el-col :span="24">
                <div class="pd-t-4 text-center">
                  <div class="block text-center">
                    <el-pagination
                      @size-change="handleSizeChange"
                      @current-change="handleCurrentChange"
                      :current-page.sync="pageNow"
                      :page-sizes="[16, 30, 50, 100, 500]"
                      :page-size="16"
                      layout="total, sizes, prev, pager, next, jumper"
                      :total="totalPage"
                    >
                    </el-pagination>
                  </div>

                  <!-- <el-pagination
                  background
                  layout="prev, pager, next"
                  :total="totalPage"
                  :page-size="limit"
                  :current-page="pageNow"
                  @current-change="handleCurrentChange"
                >
                </el-pagination> -->
                </div>
              </el-col>
            </el-row>
            <div class="text-center mg-t-3" v-else>
              <p class="font-30 font-weight-500">ไม่พบข้อมูลที่คุณค้นหา</p>
            </div>
          </div>
        </div>
        <div
          v-loading.lock="loadingKeep1"
          element-loading-text="กำลังโหลดข้อมูล..."
        >
          <CriteriaKeep
            v-if="switchChangeType && checkKeepByRole() && !loadingKeep"
            :dataRes="dataResKeep"
            :totalPage="totalPageKeep"
            :pageNow="pageNowKeep"
            :loading="loadingKeep"
            :dataSearch="dataSearch"
            :user="user"
            @changePage="changePage"
            @cancleKeep="setModalCancleKeep"
            @handleSizeChangeKeep="handleSizeChangeKeep"
          />
        </div>
      </div>
    </div>
    <el-dialog
      :visible.sync="modalAdd"
      width="30%"
      class="modal-criterion"
      :before-close="closeModalAdd"
      :close-on-click-modal="false"
    >
      <div slot="title">
        <p class="text-center color-00 font-22">
          เพิ่มเกณฑ์พิจารณาจัดเกรดสินเชื่อ
        </p>
      </div>
      <el-form
        class="set-form"
        label-position="top"
        :model="formAdd"
        :rules="rules"
        ref="formCriterion"
      >
        <el-form-item
          label="ชื่อเกณฑ์"
          prop="criterionName"
          class="padding-less font-16"
        >
          <el-input
            id="criterionName"
            v-model="formAdd.criterionName"
            @blur="trimInputValue(formAdd.criterionName, 'add')"
            placeholder="เพิ่มเกณฑ์"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item
          label="ปี พ.ศ."
          prop="criterionYear"
          class="padding-less font-16"
        >
          <el-select
            id="criterionYear"
            v-model="formAdd.criterionYear"
            placeholder="เพิ่มพ.ศ."
          >
            <el-option
              v-for="item in optionYears"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item
          label="วันที่สร้างเกณฑ์"
          prop="criterionDate"
          class="padding-less font-16"
        >
          <el-input
            id="criterionDate"
            v-model="criterionDate"
            readonly
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="is-flex js-end mg-t-4">
        <el-button
          class="font-16 btn-cancle"
          id="cancleCriterion"
          @click="closeModalAdd"
          >ยกเลิก</el-button
        >
        <el-button
          type="primary"
          class="font-16"
          id="submitAddCriterion"
          @click="submitAddCriterion"
          style="min-width: 120px"
          >เพิ่มเกณฑ์</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      v-if="modalEdit"
      :visible.sync="modalEdit"
      :close-on-click-modal="false"
      :before-close="modalCloseEdit"
      width="30%"
      class="modal-criterion"
    >
      <div slot="title">
        <p class="text-center color-00 font-22">
          แก้ไขชื่อเกณฑ์พิจารณาจัดเกรดสินเชื่อ
        </p>
      </div>
      <el-form
        class="set-form"
        label-position="top"
        :model="formEdit"
        :rules="rules"
        ref="formCriterionEdit"
      >
        <el-form-item
          label="ชื่อเกณฑ์"
          prop="criterionName"
          class="padding-less font-16"
        >
          <el-input
            id="criterionNameEdit"
            v-model="formEdit.criterionName"
            @blur="trimInputValue(formEdit.criterionName, 'edit')"
            placeholder="ชื่อเกณฑ์"
            :disabled="formEdit.statusWaite == 1 ? true : false"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="วันที่สร้างเกณฑ์"
          prop="criterionDate"
          class="padding-less font-16"
        >
          <el-input
            id="criterionDate"
            v-model="criterionDate"
            readonly
          ></el-input>
        </el-form-item>
        <!-- <el-form-item
          label="ปี พ.ศ."
          prop="criterionYear"
          class="padding-less font-16"
        >
          <el-select
            id="criterionYearEdit"
            v-model="formEdit.criterionYear"
            placeholder="เพิ่มพ.ศ."
            :disabled="formEdit.statusWaite == 1 ? true : false"
          >
            <el-option
              v-for="item in optionYears"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item> -->
      </el-form>
      <span
        v-if="formEdit.statusWaite == 1"
        class="font-18"
        style="color: #757575"
      >
        <i class="el-icon-loading"></i>
        <span class="pd-x-7">{{
          formEdit.waiteBy.empFname + " " + formEdit.waiteBy.empLname
        }}</span>
        กำลังแก้ไข
      </span>
      <div class="is-flex js-end mg-t-4">
        <el-button
          id="cancleEditCriterion"
          class="font-16 btn-cancle"
          @click="modalCloseEdit"
          >ยกเลิก</el-button
        >
        <el-button
          id="submitEditCriterion"
          type="primary"
          class="font-16"
          @click="submitEditCriterion"
          :disabled="formEdit.statusWaite == 1 ? true : false"
          style="min-width: 120px"
          >บันทึก</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="modalManage"
      width="30%"
      class="modal-criterion"
      :before-close="modalCloseGrade"
      :close-on-click-modal="false"
    >
      <el-dialog
        top="20vh"
        width="25%"
        :visible.sync="modalDelete"
        append-to-body
        :close-on-click-modal="false"
      >
        <div slot="title">
          <p class="color-00 font-24">คุณต้องการที่จะลบเกรดหรือไม่</p>
        </div>
        <div class="is-flex js-end mg-t-4">
          <el-button class="font-16 btn-cancle" @click="modalDelete = false"
            >ไม่ต้องการ</el-button
          >
          <el-button
            @click="submitDeleteGrade"
            :loading="loadingSave"
            type="danger"
            class="font-16"
            style="min-width: 120px"
            >ต้องการ</el-button
          >
        </div>
      </el-dialog>
      <div slot="title">
        <p class="text-center color-00 font-22">จัดการข้อมูลเกรดสินเชื่อ</p>
      </div>
      <div v-loading="loadingGetData">
        <!-- ให้เซฟก่อนแล้วค่อยย้ายได้ -->
        <draggable
          handle=".move"
          class="list-group set-drag-grade"
          :class="arrNow.statusWaite == 1 ? 'cursor-none disabled' : ''"
          tag="ul"
          v-model="formManage"
          v-bind="dragOptions"
          @start="isDragging = true"
          @end="isDragging = false"
          @change="saveDragAndDrop"
        >
          <transition-group type="transition" name="flip-list">
            <li
              class="list-group-item is-flex js-between ai-center mg-b-6"
              v-for="(grade, index) in formManage"
              :key="index"
              :class="grade.statusEdit == false ? 'move' : ''"
              v-if="grade.gradeName != 'Non-Prospect'"
            >
              <!-- <i
                :class="
                  grade.fixed ? 'fa fa-anchor' : 'glyphicon glyphicon-pushpin'
                "
                @click="grade.fixed = !grade.fixed"
                aria-hidden="true"
              ></i>
              {{ grade.gradeName }} -->
              <p class="color-35 font-16">
                <el-input
                  :id="`gradeName` + (index + 1)"
                  v-model="grade.gradeName"
                  v-if="grade.statusEdit"
                  @blur="grade.gradeName = grade.gradeName.trim()"
                ></el-input>
                <span v-else> {{ grade.gradeName }}</span>
              </p>
              <div
                class="is-flex"
                style="min-width: 50px"
                :class="grade.statusEdit == true ? 'text-right' : ''"
              >
                <el-button
                  v-if="grade.statusEdit"
                  class="btn-save"
                  :class="arrNow.statusWaite == 1 ? 'disabled' : ''"
                  @click="editListGrade(index, 'save')"
                >
                  <i class="fas fa-save font-14"></i>
                </el-button>
                <el-button
                  v-else
                  class="btn-edit"
                  :class="
                    grade.gradeName == 'Non-Prospect' || arrNow.statusWaite == 1
                      ? 'disabled'
                      : ''
                  "
                  @click="editListGrade(index), (grade.statusEdit = true)"
                >
                  <i class="fas fa-edit font-14"></i>
                </el-button>

                <el-button
                  class="btn-delete"
                  :class="
                    (grade.gradeName == 'Non-Prospect' && grade._id != '') ||
                    arrNow.statusWaite == 1
                      ? 'cursor-none disabled'
                      : ''
                  "
                  @click="openDelete(index)"
                >
                  <i class="fas fa-trash-alt font-14"></i>
                </el-button>
              </div>
            </li>
          </transition-group>
        </draggable>
        <!-- <div
          class="is-flex js-between ai-center mg-b-6"
          v-for="(grade, index) in formManage"
          :key="index"
          v-if="grade.gradeName != 'Non-Prospect'"
        >
          <p class="color-35 font-16">
            <el-input
              :id="`gradeName` + (index + 1)"
              v-model="grade.gradeName"
              v-if="grade.statusEdit"
              @blur="grade.gradeName = grade.gradeName.trim()"
            ></el-input>
            <span v-else> {{ grade.gradeName }}</span>
          </p>
          <div
            class="is-flex"
            style="min-width: 50px"
            :class="grade.statusEdit == true ? 'text-right' : ''"
          >
            <el-button
              v-if="grade.statusEdit"
              class="btn-save"
              @click="editListGrade(index, 'save')"
            >
              <i class="fas fa-save font-14"></i>
            </el-button>
            <el-button
              v-else
              class="btn-edit"
              :class="grade.gradeName == 'Non-Prospect' ? 'disabled' : ''"
              @click="editListGrade(index), (grade.statusEdit = true)"
            >
              <i class="fas fa-edit font-14"></i>
            </el-button>

            <el-button
              class="btn-delete"
              :class="
                grade.gradeName == 'Non-Prospect' && grade._id != ''
                  ? 'cursor-none disabled'
                  : ''
              "
              @click="openDelete(index)"
            >
              <i class="fas fa-trash-alt font-14"></i>
            </el-button>
          </div>
        </div> -->
        <div class="is-flex js-between ai-center mg-b-6">
          <p class="color-35 font-16">
            <span>Non-Prospect</span>
          </p>
          <div class="is-flex" style="min-width: 50px">
            <el-button class="btn-edit disabled">
              <i class="fas fa-edit font-14"></i>
            </el-button>

            <el-button class="btn-delete cursor-none disabled">
              <i class="fas fa-trash-alt font-14"></i>
            </el-button>
          </div>
        </div>
      </div>

      <p class="font-14 color-main">
        <span
          id="addListGrade"
          @click="addListGrade"
          class="cursor-pointer"
          :class="
            checkIfAddTrue() == true || arrNow.statusWaite == 1
              ? 'cursor-none color-c4'
              : ''
          "
        >
          <i class="fas fa-plus mg-r-7"></i> เพิ่มชื่อเกรด
        </span>
      </p>
      <span
        v-if="arrNow.statusWaite == 1"
        class="font-18"
        style="color: #757575"
      >
        <i class="el-icon-loading"></i>
        <span class="pd-x-7">{{
          arrNow.waiteBy.empFname + " " + arrNow.waiteBy.empLname
        }}</span>
        กำลังแก้ไข
      </span>
      <!-- <div class="is-flex js-end mg-t-4">
        
        <el-button
          id="submitManageCriterion"
          type="primary"
          class="font-16"
          @click="submitManageCriterion"
          style="min-width: 120px"
          >บันทึก</el-button
        >
      </div> -->
    </el-dialog>
    <el-dialog
      :visible.sync="modalKeep"
      :close-on-click-modal="false"
      width="30%"
      class="modal-criterion"
    >
      <div slot="title">
        <p class="color-00 font-24">คุณต้องการที่จะจัดเก็บเกณฑ์หรือไม่</p>
      </div>
      <div class="is-flex js-end mg-t-4">
        <el-button
          id="cancleKeepCriterion"
          class="font-16 btn-cancle"
          @click="modalKeep = false"
          >ไม่ต้องการ</el-button
        >
        <el-button
          id="submitKeepCriterion"
          type="primary"
          class="font-16"
          @click="submitKeepCriterion"
          style="min-width: 120px"
          >ต้องการ</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="modalActive"
      :close-on-click-modal="false"
      width="30%"
      class="modal-criterion"
    >
      <div slot="title">
        <p class="text-center color-00 font-22">
          ยืนยันการเปลี่ยนแปลงเกณฑ์พิจารณา
        </p>
      </div>
      <div class="is-flex js-end mg-t-4">
        <el-button class="font-16 btn-cancle" @click="modalActive = false"
          >ยกเลิก</el-button
        >
        <el-button
          type="primary"
          class="font-16"
          @click="activeCriteria(idActive)"
          style="min-width: 120px"
          >ยืนยัน</el-button
        >
      </div>
    </el-dialog>
    <el-drawer
      class="drawer-criterion"
      :visible.sync="modalTransectionLog"
      :with-header="false"
    >
      <div class="box-header normal">
        <div class="text-center" style="position: relative">
          <span> เก็บประวัติเกณฑ์พิจารณาจัดเกรดสินเชื่อ </span>
          <span class="icon-close" @click="modalTransectionLog = false">
            X
          </span>
        </div>
      </div>
      <div id="custom-collapse" v-if="modalTransectionLog">
        <div v-if="arrLog.length > 0">
          <div v-for="(log, indexF) in arrLog" :key="indexF" class="detail-log">
            <p class="font-20">
              <span class="main">{{ log.employee }} </span>
              <span>ได้{{ log.action }}</span>
              <span class="main">{{ log.desc }}</span>
              <span>ในปี</span>
              <span class="main">{{ log.year }}</span>
            </p>
            <p v-if="log.activeDate != null && log.unActiveDate != null">
              <span>Active เกณฑ์นี้เมื่อ </span>
              <span class="main"
                >{{ log.activeDate | formatDateTHFull }} น.</span
              >
              <span> ถึง </span>
              <span class="main"
                >{{ log.unActiveDate | formatDateTHFull }} น.</span
              >
            </p>

            <p class="font-18">{{ log.logTime | fromNow }}</p>
          </div>
        </div>
        <p class="font-20 color-75 text-center mg-0" v-else>ไม่มีข้อมูล</p>
      </div>
    </el-drawer>
    <el-dialog
      :visible.sync="modalCancleKeep"
      :close-on-click-modal="false"
      width="30%"
      class="modal-criterion"
    >
      <div slot="title">
        <p class="color-00 font-24">คุณต้องการที่จะยกเลิกจัดเก็บเกณฑ์หรือไม่</p>
      </div>
      <div class="is-flex js-end mg-t-4">
        <el-button
          id="cancleKeepCriterion"
          class="font-16 btn-cancle"
          @click="modalCancleKeep = false"
          >ไม่ต้องการ</el-button
        >
        <el-button
          id="submitCancleKeepCriterion"
          type="primary"
          class="font-16"
          @click="submitCancleKeepCriterion"
          style="min-width: 120px"
          >ต้องการ</el-button
        >
      </div>
    </el-dialog>
  </DefaultTemplate>
</template>

<script>
import DefaultTemplate from "@/template/DefaultTemplate";
import CriteriaKeep from "@/components/criteria/CriteriaKeep";

import { HTTP } from "@/service/axios";
import { mapState } from "vuex";
import draggable from "vuedraggable";
import moment from "moment";
export default {
  display: "Transition",
  components: {
    DefaultTemplate,
    CriteriaKeep,
    draggable,
  },

  data() {
    return {
      myArray: [],
      modalCancleKeep: false,
      loadingGetData: false,
      modalTransectionLog: false,
      idActive: null,
      modalActive: false,
      modalAdd: false,
      modalEdit: false,
      modalManage: false,
      modalKeep: false,
      dataSearch: "",
      formAdd: {
        criterionName: "",
        criterionYear: "",
      },
      formEdit: {
        _id: "",
        criterionName: "",
        criterionYear: "",
      },
      formManage: [],
      optionYears: [],
      dataRes: [],
      loading: false,
      loadingNormal: false,
      loadingKeep: true,
      loadingKeep1: true,
      rules: {
        criterionName: [
          {
            required: true,
            message: "กรุณากรอกชื่อเกณฑ์",
            trigger: ["blur", "change"],
          },
        ],
        criterionYear: [
          {
            required: true,
            message: "กรุณาเลือกปี พ.ศ.",
            trigger: ["blur", "change"],
          },
        ],
      },
      pageNow: 1,
      pageNowKeep: 1,
      totalPage: "",
      limit: 16,
      limitKeep: 16,
      debounce: null,
      keepId: null,
      arrLog: [],
      modalDelete: false,
      loadingSave: false,
      formDelete: [],
      switchChangeType: this.$store.state.showHideCriteria,
      totalPageKeep: "",
      dataResKeep: [],
      idCancleKeep: null,
      idData: "",
      statusView: null,
      arrNow: [],
      activeEdit: null,
      updateCriteria: false,
      loadingData: true,
      criterionDate: null,
    };
  },
  computed: {
    ...mapState({
      _token: (state) => state._token,
      user: (state) => state.user,
      permissionNow: (state) => state.permissionNow,
      showHideCriteria: (state) => state.showHideCriteria,
    }),
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },

  async beforeDestroy() {
    if (this.activeEdit == 1) {
      await this.destroyedEdit();
    }
  },
  async created() {
    window.addEventListener(
      "beforeunload",
      async () => {
        for (let i = 0; i < this.dataRes.data.length; i++) {
          if (this.activeEdit == 1) {
            if (this.idData == this.dataRes.data[i]._id) {
              let obj = {
                id: this.dataRes.data[i]._id,
                statusWaite: 0,
                waiteBy: this.user.id,
                status: 1,
                editBy: this.user.id,
              };

              HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
              let res = await HTTP.post(`criterion/write`, obj);
              return await res;
            }
          }
        }
      },
      false
    );
  },
  mounted() {
    this.$store.commit("SET_PAGE_ACTIVE", "criteria-credit");
    this.getCriteriaKeep();
    this.getCriteria();
    this.getYears();
  },
  methods: {
    dateThai(date) {
      return moment(date).format("DD/MM/") + (moment(date).year() + 543);
    },
    showModalAdd() {
      let currentDate = moment();
      this.criterionDate = this.dateThai(currentDate);
      this.modalAdd = true;
    },
    closeModalAdd() {
      this.criterionDate = null;
      this.modalAdd = false;
      this.$refs.formCriterion.resetFields();
    },
    handleSizeChange(val) {
      this.limit = val;
      this.handleCurrentChange(1);
    },
    handleSizeChangeKeep(val) {
      this.limitKeep = val;
      this.changePage(1);
    },
    changePage(val) {
      this.loadingKeep1 = true;
      this.pageNowKeep = val;
      this.getCriteriaKeep();
    },
    handleCurrentChange(val) {
      this.loadingNormal = true;
      this.pageNow = val;
      this.getCriteria();
    },
    checkKeepByRole() {
      let result = false;
      if (this.user.role.roleLevel == 90 || this.user.role.roleLevel == 100) {
        result = true;
      }
      return result;
    },
    checkIfAddTrue() {
      if (this.modalManage == true) {
        let addName = this.formManage.find(
          (e) => e.statusEdit == true && e._id == ""
        );
        let result = null;
        if (typeof addName != "undefined") {
          result = true;
        } else {
          result = false;
        }
        return result;
      }
    },
    getHistoryLog() {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      HTTP.get(`history/criterion`)
        .then((res) => {
          if (res.data.success) {
            this.arrLog = res.data.obj;
            this.modalTransectionLog = true;
          }
        })
        .catch((e) => {
          if (typeof e.response != "undefined") {
            if (e.response.data.status == 401) {
              this.checkAuth();
            } else {
              this.alertCatchError(e);
            }
          }
        });
    },

    async destroyedEdit() {
      // if (this.activeEdit == true) {
      this.dataRes.data.forEach(async (criteria) => {
        if (criteria.statusWaite == 1) {
          if (this.user.id == criteria.waiteBy.id) {
            this.idData = criteria._id;
            await this.sendIsEdit(0, 1);
          }
        }
      });
      // this.dataRes.data.forEach(async (criteria) => {
      //  console.log(criteria)
      //   // if (criteria.statusWaite == 1) {
      //   //   if (this.user.id == criteria.waiteBy.id) {
      //       //     this.idData = 5;
      //       // await this.sendIsEdit(0, 1);
      //   //     //     this.statusView = false;
      //   //     //   } else {
      //   //     //     this.statusView = true;
      //   //   }
      //   //   // } else {
      //   //   //   this.statusView = false;
      //   // }
      // });
      // }
    },
    getCriteria() {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      let obj = {
        page: this.pageNow,
        limit: this.limit,
        key: this.dataSearch,
      };
      HTTP.post(`criterion`, obj)
        .then((res) => {
          if (res.data.success) {
            this.dataRes = res.data.obj;
            this.dataRes.data = res.data.obj.data.map((e) => ({
              ...e,
              setActive: false,
            }));

            this.dataRes.data.forEach(async (criteria) => {
              if (criteria.statusWaite == 1) {
                if (this.user.id == criteria.waiteBy.id) {
                  this.idData = criteria._id;
                  if (this.updateCriteria == false) {
                    await this.sendIsEdit(0, 1);
                  } else {
                    criteria.statusWaite = 0;
                  }
                }
              }
            });

            this.loadingData = false;
            this.loading = false;
            this.loadingNormal = false;
            this.totalPage = res.data.obj.totalPage;
          } else {
            if (res.data.status == 403) {
              this.$store.commit("SET_PAGE_ACTIVE", null);
              this.$router.push("/not-allowed");
            }
          }
        })
        .catch((e) => {
          if (e.response.status != 502) {
            if (e.response.data.status == 401) {
              this.checkAuth();
            } else if (e.response.data.status == 502) {
              console.log("Bad GetWay");
            } else {
              this.alertCatchError(e);
            }
          }
        });
    },
    getCriteriaKeep() {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      let obj = {
        page: this.pageNowKeep,
        limit: this.limitKeep,
        key: this.dataSearch,
      };
      HTTP.post(`criterion/kep`, obj)
        .then((res) => {
          if (res.data.success) {
            this.dataResKeep = res.data.obj;
            this.totalPageKeep = res.data.obj.totalPage;
            this.loadingKeep = false;
            this.loadingKeep1 = false;
          } else {
            if (res.data.status == 403) {
              this.$store.commit("SET_PAGE_ACTIVE", null);
              this.$router.push("/not-allowed");
            }
          }
        })
        .catch((e) => {
          if (e.response.data.status == 401) {
            this.checkAuth();
          } else if (e.response.data.status == 502) {
            console.log("Bad GetWay");
          } else {
            this.alertCatchError(e);
          }
        });
    },
    searchCriteria() {
      this.pageNowKeep = 1;
      this.pageNow = 1;
      this.loading = true;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.getCriteria();
        this.getCriteriaKeep();
      }, 600);
    },
    getYears() {
      let d = new Date();
      let years = d.getFullYear() - 1;
      for (let index = 0; index < 7; index++) {
        let obj = {
          value: (years + index + 543).toString(),
          label: (years + index + 543).toString(),
        };
        this.optionYears.push(obj);
      }

      this.optionYears.sort((a, b) => {
        let modifier = -1;
        if (a.value < b.value) return -1 * modifier;
        if (a.value > b.value) return 1 * modifier;
        return 0;
      });
    },
    addListGrade(name = "") {
      let obj = {
        name: "",
        _id: "",
        statusEdit: true,
      };
      this.formManage.push(obj);
    },
    openDelete(index) {
      if (this.formManage[index]._id != "") {
        this.modalDelete = true;
        this.formDelete = this.formManage[index];
      } else {
        this.formManage.splice(index, 1);
      }
    },
    async editListGrade(index, type) {
      let arr = this.formManage.findIndex((f) => f == index);
      if (arr < 0) {
        this.formManage[index].statusEdit = true;
        this.formManage[index] = this.formManage[index];
        if (type == "save") {
          if (
            typeof this.formManage[index].gradeName != "undefined" &&
            this.formManage[index].gradeName != ""
          ) {
            if (
              this.formManage[index].gradeName.toLowerCase() != "non-prospect"
            ) {
              let obj = {
                gradeName: this.formManage[index].gradeName,
              };
              HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
              if (this.formManage[index]._id != "") {
                let res = await HTTP.put(
                  `criterion/grade/${this.formManage[index]._id}`,
                  obj
                )
                  .then(async (res) => {
                    if (res.data.success) {
                      this.updateCriteria = true;
                      await this.sendIsEdit(1, 2);
                      this.$message({
                        message: "แก้ไขชื่อเกรด สำเร็จ",
                        type: "success",
                        duration: 4000,
                      });
                      let checkOpenEdit = this.formManage.find(
                        (e) =>
                          e.statusEdit == true &&
                          e._id == this.formManage[index]._id
                      );
                      if (typeof checkOpenEdit != "undefined") {
                        this.formManage[index].statusEdit = false;
                      }
                      // else {
                      //   this.clearModalManage();
                      // }
                      return res;
                    } else {
                      if (res.data.status == 204) {
                        this.$message({
                          message:
                            "ชื่อข้อมูลเกรดสินเชื่อ " + obj.gradeName + " ซ้ำ",
                          type: "error",
                        });
                      }
                      return res;
                    }
                  })
                  .catch((e) => {
                    if (e.response.data.status == 401) {
                      this.checkAuth();
                    } else {
                      this.alertCatchError(e);
                    }
                    return e.response;
                  });
                return res;
              } else {
                let res = await HTTP.post(
                  `criterion/grade/${this.formManage[0].criterionId}`,
                  obj
                )
                  .then(async (res) => {
                    if (res.data.success) {
                      this.updateCriteria = true;
                      await this.sendIsEdit(1, 2);
                      this.$message({
                        message: "เพิ่มข้อมูลเกรดสินเชื่อ สำเร็จ",
                        type: "success",
                        duration: 4000,
                      });
                      this.modalCriterion(
                        this.formManage[0].criterionId,
                        "manage"
                      );
                      return res;
                    } else {
                      if (res.data.status == 204) {
                        this.$message({
                          message:
                            "ชื่อข้อมูลเกรดสินเชื่อ " + obj.gradeName + " ซ้ำ",
                          type: "error",
                        });
                      }
                      return res;
                    }
                  })
                  .catch((e) => {
                    if (e.response.data.status == 401) {
                      this.checkAuth();
                    } else {
                      this.alertCatchError(e);
                    }
                    return e.response;
                  });
                return res;
              }
            } else {
              this.$message({
                message: "ไม่สามารถตั้งชื่อ Non-Prospect นี้ได้",
                type: "error",
                duration: 4000,
              });
              return {
                data: {
                  success: false,
                  data: "ไม่สามารถตั้งชื่อ Non-Prospect นี้ได้",
                },
              };
            }
          } else {
            this.$message({
              message: "กรุณากรอกข้อมูลเกรดสินเชื่อ",
              type: "error",
              duration: 4000,
            });
            return {
              data: {
                success: false,
                data: "กรุณากรอกข้อมูลเกรดสินเชื่อ",
                index: index,
                obj: this.formManage,
              },
            };
          }
        }
        return {
          data: {
            success: false,
          },
        };
      }
    },
    async modalCriterion(id, detail) {
      let getArr = [];

      this.idData = id;
      if (detail == "edit") {
        this.modalEdit = true;
        await HTTP.get(`criterion/edit/${id}`)
          .then((res) => {
            if (res.data.success) {
              let criterionDate = moment(res.data.obj.createdAt);
              this.criterionDate = this.dateThai(criterionDate);
              this.formEdit = res.data.obj;
              getArr.push(this.formEdit);
            } else {
              if (res.data.status == 403) {
                this.modalEdit = false;
                this.$message({
                  message: "คุณไม่มีสิทธิเข้าถึง",
                  type: "error",
                  duration: 4000,
                });
              }
            }
          })
          .catch((e) => {
            if (e.response.data.status == 401) {
              this.checkAuth();
            } else {
              this.alertCatchError(e);
            }
          })
          .finally(() => {
            this.loadingGetData = false;
          });
      } else if (detail == "manage") {
        await this.getCriteria();
        this.formManage = [];
        await HTTP.get(`criterion/grade/${id}`)
          .then(async (res) => {
            if (res.data.success) {
              this.modalManage = true;
              let objNon = {};
              res.data.obj.forEach((f) => {
                if (f.gradeName != "Non-Prospect") {
                  this.formManage.push({
                    ...f,
                    statusEdit: false,
                  });
                } else {
                  objNon = {
                    ...f,
                    statusEdit: false,
                  };
                }
              });
              this.formManage.push(objNon);
              getArr = await this.dataRes.data;
              let findArr = getArr.find((e) => e._id == id);
              this.arrNow = await findArr;
              if (findArr.statusWaite != 1 && findArr.active == false) {
                await this.sendIsEdit(1, 1);
              }
            } else {
              if (res.data.status == 403) {
                this.modalManage = false;
                this.$message({
                  message: "คุณไม่มีสิทธิเข้าถึง",
                  type: "error",
                  duration: 4000,
                });
              }
            }
          })
          .catch((e) => {
            if (e.response.data.status == 401) {
              this.checkAuth();
            } else {
              this.alertCatchError(e);
            }
          })
          .finally(() => {
            this.loadingGetData = false;
          });
      } else if (detail == "keep") {
        this.keepId = id;
        this.modalKeep = true;
      }

      if (detail == "edit") {
        let findArr = await getArr.find((e) => e._id == this.idData);
        this.arrNow = await findArr;
        // this.arrNow = await findArr;
        if (findArr.statusWaite != 1 && findArr.active == false) {
          await this.sendIsEdit(1, 1);
        }
      }
    },
    async copyCriterion(id) {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      let res = await HTTP.post(`criterion/copy/${id}`)
        .then((res) => {
          if (res.data.success) {
            this.$message({
              message: "คัดลอกเกณฑ์ สำเร็จ",
              type: "success",
              duration: 4000,
            });
            this.getCriteria();
          }
          return res;
        })
        .catch((e) => {
          if (e.response.data.status == 401) {
            this.checkAuth();
          } else {
            this.alertCatchError(e);
          }
          return e.response;
        });
      return res;
    },
    async apiAddCriterion() {
      let obj = {
        criterionName: this.formAdd.criterionName,
        // criterionYear: this.formAdd.criterionYear,
      };
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      let res = await HTTP.post(`criterion/create`, obj)
        .then((res) => {
          if (res.data.success) {
            this.$message({
              message: "เพิ่มเกณฑ์พิจารณาจัดเกรดสินเชื่อ สำเร็จ",
              type: "success",
              duration: 4000,
            });
            this.getCriteria();
            this.modalAdd = false;

            return res;
          } else {
            if (res.data.status == 204) {
              this.$message({
                message: "ชื่อเกณฑ์พิจารณาจัดเกรดสินเชื่อ ซ้ำ",
                type: "error",
                duration: 4000,
              });
            }
            return res;
          }
        })
        .catch((e) => {
          if (typeof e.response != "undefined") {
            if (e.response.data.status == 401) {
              this.checkAuth();
            } else {
              this.alertCatchError(e);
            }
          }
          // if (typeof e.response.data != "undefined") {
          //   if (e.response.data.status == 401) {
          //     this.checkAuth();
          //   } else {
          //     this.alertCatchError(e);
          //   }
          // }
          // return {
          //   data: {
          //     success: false,
          //   },
          // };
          //   if (typeof e.response.data != "undefined") {
          //   if (e.response.data.status == 401) {
          //     this.checkAuth();
          //   } else {
          //     this.alertCatchError(e);
          //   }
          // }
          // return e.response;
        });
      return res;
    },
    submitAddCriterion() {
      this.$refs["formCriterion"].validate().then(async (valid) => {
        if (valid) {
          await this.apiAddCriterion();
          let debounce = null;
          clearTimeout(debounce);
          debounce = setTimeout(() => {
            this.$refs.formCriterion.resetFields();
          }, 600);
        }
      });
    },
    async apiEditCriterion() {
      var res = await this.$store.dispatch("criterion/UPDATE", this.formEdit);
      var httpStatus = !!res.status ? res.status : 0;
      switch (httpStatus) {
        case 200:
          this.$message({
            message: "แก้ไขชื่อเกณฑ์พิจารณาจัดเกรดสินเชื่อ สำเร็จ",
            type: "success",
            duration: 4000,
          });
          this.getCriteria();
          this.modalEdit = false;
          return;
        case 204:
          this.$message({
            message: "ชื่อเกณฑ์พิจารณาจัดเกรดสินเชื่อ ซ้ำ",
            type: "error",
            duration: 4000,
          });
          return;
        case 401:
          this.checkAuth();
          return;
        case 502:
          console.log("Bad GetWay");
          return;
        default:
          let message = !!res.data ? res.data : res;
          this.alertCatchError(message);
          return;
      }
      // let obj = {
      //   criterionName: this.formEdit.criterionName,
      //   // criterionYear: this.formEdit.criterionYear,
      // };
      // HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      // console.log("response: ", response);
      // let res = HTTP.put(`criterion/${this.formEdit._id}`, obj)
      //   .then((res) => {
      //     if (res.data.success) {
      //       this.$message({
      //         message: "แก้ไขชื่อเกณฑ์พิจารณาจัดเกรดสินเชื่อ สำเร็จ",
      //         type: "success",
      //         duration: 4000,
      //       });
      //       this.getCriteria();
      //       this.modalEdit = false;
      //       return res;
      //     } else {
      //       if (res.data.status == 204) {
      //         this.$message({
      //           message: "ชื่อเกณฑ์พิจารณาจัดเกรดสินเชื่อ ซ้ำ",
      //           type: "error",
      //           duration: 4000,
      //         });
      //       }
      //       return res;
      //     }
      //   })
      //   .catch((e) => {
      //     console.log("err update criterion: ", e);
      //     if (typeof e.response.data != "undefined") {
      //       if (e.response.data.status == 401) {
      //         this.checkAuth();
      //       } else if (e.response.data.status == 502) {
      //         console.log("Bad GetWay");
      //       } else {
      //         this.alertCatchError(e);
      //       }
      //     }
      //     return e.response;
      //   });
      // return res;
    },
    submitEditCriterion() {
      this.$refs["formCriterionEdit"].validate().then(async (valid) => {
        if (valid) {
          await this.apiEditCriterion();
        }
      });
    },
    async submitKeepCriterion() {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      let res = await HTTP.put(`criterion/kep/${this.keepId}`)
        .then((res) => {
          if (res.data.success) {
            this.$message({
              message: "จัดเก็บเกณฑ์พิจารณาจัดเกรดสินเชื่อ สำเร็จ",
              type: "success",
              duration: 4000,
            });
            this.pageNow = 1;
            this.getCriteria();
            this.getCriteriaKeep();
            this.modalKeep = false;
            this.keepId = null;
          } else {
            if (res.data.status == 403) {
              this.modalKeep = false;
              this.$message({
                message: "คุณไม่มีสิทธิเข้าถึง",
                type: "error",
                duration: 4000,
              });
            }
          }
          return res;
        })
        .catch((e) => {
          if (e.response.data.status == 401) {
            this.checkAuth();
          } else {
            this.alertCatchError(e);
          }
          return e.response;
        });
      return res;
    },
    openModalActive(id) {
      this.modalActive = true;
      this.idActive = id;
    },
    async activeCriteria(id) {
      // ยืนยันการเปลี่ยนแปลงเกณฑ์พิจารณา;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      let res = await HTTP.put(`criterion/active/${id}`)
        .then((res) => {
          if (res.data.success) {
            this.modalActive = false;
            this.idActive = null;
            this.$message({
              message: "Active เกณฑ์พิจารณาจัดเกรดสินเชื่อ สำเร็จ",
              type: "success",
              duration: 4000,
            });
            this.getCriteria();
          }
          return res;
        })
        .catch((e) => {
          if (e.response.data.status == 401) {
            this.checkAuth();
          } else {
            this.alertCatchError(e);
          }
          return e.response;
        });
      return res;
    },

    // เอาออกตามดีไซน์
    // submitManageCriterion() {
    //   let findObjName = this.formManage.find(
    //     (f) =>
    //       (f.name == "" && typeof f.gradeName == "undefined") ||
    //       f.gradeName == ""
    //   );
    //   if (typeof findObjName != "undefined") {
    //     this.$message({
    //       message: "กรุณากรอกข้อมูลเกรดสินเชื่อ",
    //       type: "error",
    //       duration: 4000,
    //     });
    //   } else {
    //     // let grade = [];
    //     const grade = this.formManage.reduce((result, f) => {
    //       const obj = { id: f._id, gradeName: f.gradeName };

    //       if (result.indexOf(obj) === -1) result.push(obj);
    //       return result;
    //     }, []);
    //     HTTP.post(`criterion/grade/edit/${this.formManage[0].criterionId}`, {
    //       grade: grade,
    //     })
    //       .then((res) => {
    //         if (res.data.success) {
    //           this.$message({
    //             message: "แก้ไขข้อมูลเกรดสินเชื่อ สำเร็จ",
    //             type: "success",
    //             duration: 4000,
    //           });
    //           this.clearModalManage();
    //         } else {
    //           if (res.data.status == 204) {
    //             if (res.data.obj.save != null) {
    //               for (let i = 0; i < res.data.obj.save.length; i++) {
    //                 let objSave = this.formManage.find(
    //                   (e) => e._id == res.data.obj.save[i]._id
    //                 );
    //                 objSave.statusEdit = false;
    //               }
    //             }
    //             if (res.data.obj.error != null) {
    //               this.$message({
    //                 message:
    //                   "ชื่อข้อมูลเกรดสินเชื่อ " +
    //                   res.data.obj.error.gradeName +
    //                   " ซ้ำ",
    //                 type: "error",
    //               });
    //             }
    //           }
    //         }
    //       })
    //       .catch((e) => {
    //         if (e.response.data.status == 401) {
    //           this.checkAuth();
    //         } else {
    //           this.alertCatchError(e);
    //         }
    //       });
    //   }
    // },
    checkPermissionMore() {
      let ifPermission = this.permissionNow.some(
        (e) => e.pemNum == this.$route.meta.permission && e.status == 2
      );
      if (ifPermission === false) {
        return false;
      } else {
        return true;
      }
    },
    trimInputValue(val, type) {
      if (type == "add") {
        this.formAdd.criterionName = val.trim();
      } else if (type == "edit") {
        this.formEdit.criterionName = val.trim();
      }
    },

    async submitDeleteGrade() {
      this.loadingSave = true;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      let obj = {
        gradeName: this.formDelete.gradeName,
      };
      let res = await HTTP.delete(`criterion/grade/${this.formDelete._id}`, obj)
        .then(async (res) => {
          if (res.data.success) {
            this.updateCriteria = true;
            await this.sendIsEdit(1, 2);
            this.$message({
              message: "ลบเกรด สำเร็จ",
              type: "success",
              duration: 4000,
            });
            this.modalDelete = false;
            this.loadingGetData = true;
            this.modalCriterion(this.formManage[0].criterionId, "manage");
            let debounce = null;
            clearTimeout(debounce);
            debounce = setTimeout(() => {
              this.loadingSave = false;
            }, 600);
          }
          return res;
        })
        .catch((e) => {
          if (e.response.data.status == 401) {
            this.checkAuth();
          } else {
            this.alertCatchError(e);
          }
          return e.response;
        });
      return res;
    },
    changeType() {
      if (this.switchChangeType == true) {
        this.$store.commit("SET_SHOW_CRITERIA", true);
      } else {
        this.$store.commit("SET_SHOW_CRITERIA", false);
      }
    },
    setModalCancleKeep(id) {
      this.modalCancleKeep = true;
      this.idCancleKeep = id;
    },
    async submitCancleKeepCriterion() {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      let res = await HTTP.put(`criterion/kep/cancel/${this.idCancleKeep}`)
        .then((res) => {
          if (res.data.success) {
            this.modalCancleKeep = false;
            this.idCancleKeep = null;
            this.$message({
              message: "ยกเลิกจัดเก็บเกณฑ์พิจารณาจัดเกรดสินเชื่อ สำเร็จ",
              type: "success",
              duration: 4000,
            });
            this.pageNow = 1;
            this.getCriteria();
            this.getCriteriaKeep();
          }
          return res;
        })
        .catch((e) => {
          if (e.response.data.status == 401) {
            this.checkAuth();
          } else {
            this.alertCatchError(e);
          }
          return e.response;
        });
      return res;
    },
    saveDragAndDrop() {
      let obj = {
        grade: this.formManage.map((row, index) => {
          return {
            _id: row._id,
            gradeNo: index + 1,
          };
        }),
      };

      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;

      HTTP.put(`criterion/grade/change`, obj)
        .then(async (res) => {
          if (res.data.success) {
            this.updateCriteria = true;
            await this.sendIsEdit(1, 2);
            // this.dataResKeep = res.data.obj;
            // this.totalPageKeep = res.data.obj.totalPage;
            // this.loadingKeep = false;
          } else {
            if (res.data.status == 403) {
              this.$store.commit("SET_PAGE_ACTIVE", null);
              this.$router.push("/not-allowed");
            }
          }
        })
        .catch((e) => {
          if (e.response.data.status == 401) {
            this.checkAuth();
          } else {
            this.alertCatchError(e);
          }
        });
    },
    async sendIsEdit(statusWaite, statusEdit) {
      let obj = {
        id: this.idData,
        statusWaite: statusWaite,
        waiteBy: this.user.id,
        status: statusEdit,
        editBy: this.user.id,
      };
      this.activeEdit = statusWaite;

      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      let res = await HTTP.post(`criterion/write`, obj);
      return await res;
    },

    async clearModalManage() {
      this.modalManage = false;

      if (this.updateCriteria == false) {
        await this.destroyedEdit();
      } else {
        if (this.activeEdit == 1) {
          await this.sendIsEdit(0, 1);
        }
      }
      this.updateCriteria = false;

      // if (this.activeEdit == 1) {
      //   this.sendIsEdit(0, 1);
      // }
    },
    modalCloseEdit() {
      this.modalEdit = false;
      if (this.activeEdit == 1) {
        this.sendIsEdit(0, 1);
      }
    },
    modalCloseGrade() {
      this.modalManage = false;
      if (this.activeEdit == 1) {
        this.sendIsEdit(0, 1);
      }
    },
  },
};
</script>
