<template>
  <div class="mg-t-1 pd-x-0">
    <hr class="mg-b-5" />
    <div class="box-name-page mg-b-5">
      <p class="title">เกณฑ์พิจารณาจัดเกรดสินเชื่อ ที่ถูกจัดเก็บ</p>
    </div>
    <div class="animate__animated animate__fadeIn animate__slow">
      <el-row :gutter="20" v-if="dataRes.data.length > 0">
        <el-form label-position="top" class="set-form">
          <el-col
            :span="24"
            :md="12"
            class="mg-t-5"
            v-for="(data, index) in dataRes.data"
            :key="index"
          >
            <div class="box-criteria keep-active">
              <div class="criteria-left">
                <div class="box">
                  <span class="color-35">Deactivate</span>
                </div>
                <div>
                  <p class="color-35 font-20 mg-t-0 name-criteria">
                    {{ data.criterionName }}
                  </p>
                  <p class="color-75 font-18 mg-b-0">
                    ปี {{ data.criterionYear }}
                  </p>
                </div>
              </div>
              <div class="criteria-right">
                <el-button
                  class="mg-r-6 more-criteria keep color-c4"
                  v-if="checkPermissionMore()"
                  @click="openModal(data._id)"
                >
                  <span class="">
                    <i class="fas fa-trash-restore"></i>
                    ยกเลิกจัดเก็บเกณฑ์
                  </span>
                </el-button>
              </div>
            </div>
          </el-col>
        </el-form>
        <el-col :span="24">
          <div class="pd-t-4 text-center">
            <el-pagination
              @size-change="handleSizeChangeKeep"
              @current-change="handleCurrentChangeKeep"
              :current-page.sync="page"
              :page-sizes="[16, 30, 50, 100, 500]"
              :page-size="16"
              layout="total, sizes, prev, pager, next, jumper"
              :total="totalPage"
            >
            </el-pagination>
            <!-- <el-pagination
              background
              layout="prev, pager, next"
              :total="totalPage"
              :page-size="limit"
              :current-page="page"
              @current-change="handleCurrentChangeKeep"
            >
            </el-pagination> -->
          </div>
        </el-col>
      </el-row>
      <div class="text-center mg-t-3 font-30 font-weight-500" v-else>
        <span v-if="dataSearch != ''"> ไม่พบข้อมูลที่คุณค้นหา </span>
        <span v-else> ไม่มีข้อมูล </span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  props: ["totalPage", "dataRes", "pageNow", "loading", "dataSearch"],
  watch: {
    dataRes() {
      this.setData();
    },
  },
  data() {
    return {
      page: null,
    };
  },
  ...mapState({
    permissionNow: (state) => state.permissionNow,
  }),
  methods: {
    setData() {
      this.dataRes = this.dataRes;
      this.page = this.pageNow;
    },
    checkPermissionMore() {
      let ifPermission = this.permissionNow.some(
        (e) => e.pemNum == this.$route.meta.permission && e.status == 2
      );
      if (ifPermission === false) {
        return false;
      } else {
        return true;
      }
    },
    handleCurrentChangeKeep(val) {
      this.$emit("changePage", val);
    },
    handleSizeChangeKeep(val) {
      this.$emit("handleSizeChangeKeep", val);
    },
    openModal(id) {
      // this.modalCancleKeep = true;
      this.$emit("cancleKeep", id);
    },
  },
};
</script>